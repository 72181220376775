import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Header from '../components/header';
import '../components/style/layout.css';
import '../components/style/fonts.css';
import Transition from '../components/transition';
import Footer from '../components/footer';

const ResponsiveContainer = styled.div`
  width: 90%;
  max-width: 992px;
  margin: auto;
  main {
    min-height: 60vh;
    margin-top: 3vh;
    padding-bottom: 10vh;
    /*scroll-snap-align: end;*/
  }
`;

/*
import { FaHeart } from 'react-icons/fa';
function openPopUp() {
  if (window.netlifyIdentity) window.netlifyIdentity.open();
}
*/
const Layout = ({ children, location }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <ResponsiveContainer>
        <Header siteTitle={data.site.siteMetadata.title} />
        <main>
          <Transition location={location}>{children}</Transition>
        </main>
        <Footer />
      </ResponsiveContainer>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
