import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import logo from '../images/logo.png';
import { Link } from 'gatsby';
// import Signature from '../components/signature';

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? { className: 'navlink-active ' }
    : { className: '' };
};

const PartialNavLink = props => (
  <Link getProps={isPartiallyActive} {...props}>
    {props.children}
  </Link>
);
const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  height: 22vh;
  padding-bottom: 3vh;
  position: relative;
  /*scroll-snap-align: start;
  /*margin-bottom: 1rem;*/
  h1 img {
    height: 15vh;
    vertical-align: bottom;
  }
  h1 a {
    color: black;
  }
  nav {
    position: relative;
    top: 3px;
    display: flex;
    align-items: flex-end;
    width: 39.4%;
  }
  ul {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: auto;
    li {
      width: 33%;
      text-align: center;
    }
    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: flex-end;

      > li {
        width: 100%;
        > a {
          text-align: right;
        }
      }
    }
    li:first-child {
      text-align: left;
    }
    li:last-child {
      text-align: right;
    }
    a {
      display: block;
      padding: 0px;
      text-decoration: none;
      text-transform: uppercase;
      color: black;
      letter-spacing: 0.1rem;
      transition: 0.3s;
      font-weight: 400;
      font-size: 18px;
      font-family: 'Roboto', sans-serif;
      letter-spacing: 0.01rem;
      &:hover {
        letter-spacing: 0.11rem;
        font-size: '1.1rem';
      }
    }
    .navlink-active {
      letter-spacing: 0.11rem;
      font-weight: 500;
    }
  }
  /*Pseudo element 4 animation*/
`;

const Line = styled.div`
  display: block;
  width: 100%;
  height: 1px;
  background-color: black;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;
const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <h1 style={{ margin: 0 }}>
      <Link to="/realisations">
        <img src={logo} alt={siteTitle} />
      </Link>
    </h1>
    <nav>
      <ul>
        <li>
          <Link to="/agence" activeClassName="navlink-active">
            Studio
          </Link>
        </li>
        <li>
          <PartialNavLink to="/realisations">Projets</PartialNavLink>
        </li>
        <li>
          <Link to="/contact" activeClassName="navlink-active">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
    <Line />
  </HeaderWrapper>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
