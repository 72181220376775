import React from 'react';
import styled from 'styled-components';
import { FaFacebookSquare, FaInstagram } from 'react-icons/fa';

const Footer = ({ siteTitle }) => (
  <FooterWrapper>
    <div>
      <section id="left">
        <h1>Studio bc</h1>
        <ul className="hiddenMobile">
          <li>
            <a target="blank" href="https://goo.gl/maps/5pxpJZ5s6Wu2o3fw7">
              PARIS - STRASBOURG
            </a>
          </li>
          <li>
            <a href="mailto:contact@studio-bc.fr">contact@studio-bc.fr</a>
          </li>
        </ul>
      </section>
      <section id="right">
        <h2 className="hiddenMobile">Nous suivre</h2>
        <ul>
          <li>
            <a target="blank" href="https://www.facebook.com/Studiobc.Paris/">
              <FaFacebookSquare />
            </a>
          </li>
          <li>
            <a target="blank" href="https://www.instagram.com/studio__bc/">
              <FaInstagram />
            </a>
          </li>
        </ul>
      </section>
    </div>
    {/*
      <p> Copyright {new Date().getFullYear()} </p>
          <footer
            style={{
              padding: '1rem',
              position: 'fixed',
              bottom: 0,
              right: 0,
              backgroundColor: 'rgba(255,255,255,0.3)',
              borderRadius: '5px',
            }}
            >
            Made with &nbsp;
            <a href="#" onClick={openPopUp}>
              <FaHeart style={{ color: '#804444' }} />
            </a>
            &nbsp; by{' '}
            <a
              style={{ color: 'black' }}
              target="_blank"
              rel="noopener noreferrer"
              href="http://i-marty.eu"
              >
              Igor MARTY
            </a>
          </footer>
          */}
  </FooterWrapper>
);

Footer.defaultProps = {
  siteTitle: ``,
};

const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #e3ccba;
  padding: 1vh 0;
  min-height: 9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  a {
    color: white;
    text-decoration: none;
  }
  > * {
    width: 50%;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  div {
    display: flex;
    justify-content: space-between;
    text-align: center;
    color: white;
    width: 90%;
    max-width: 992px;
    margin: auto;
  }
  h2,
  h1 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    margin: 0;
    font-weight: normal;
  }
  h1 {
    font-size: 20px;
    font-weight: 500;
  }
  #right ul {
    display: flex;
    justify-content: flex-end;
    a {
      padding: 0.2rem;
      padding-right: 0;
      > * {
        font-size: 2rem;
      }
    }
  }
  #left {
    text-align: left;
  }
  #right {
    text-align: right;
  }
  @media screen and (max-width: 540px) {
    .hiddenMobile {
      display: none;
    }
  }
`;

export default Footer;
